import React, {useEffect, useState} from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

import style from '../css/index.css'

import hero_1 from '../assets/images/hero/hero_1.jpg';
import hero_2 from '../assets/images/hero/hero_2.jpg';
import hero_3 from '../assets/images/hero/hero_3.jpg';
import hero_4 from '../assets/images/hero/hero_4.jpg';

import globe from '../assets/images/globe-solid.svg';
import handshake from '../assets/images/handshake-solid.svg';
import check from '../assets/images/check-solid.svg';


const Index = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const [image, setImage] = useState(0);

  const images = [ hero_1, hero_2, hero_3, hero_4 ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (image === images.length - 1 ) {
        setImage(0);
      } else {
        setImage(image + 1);
      }
    }, 3000);
    
    return () => {
      clearInterval(interval);
    };
  }, [image]);

  return (
  <Layout>
    <section className="pt-20 pb-20 md:pb-0 md:pt-0 hero h-screen">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            CICO
          </h1>
          <p className="text-xl lg:text-3xl mt-6 font-semibold">
            Sintetička maziva visokih performansi
          </p>
          <p className="mt-8 md:mt-12">
            <a className="px-4" href="/kategorije">
              <Button size="lg">Vidi više</Button>
            </a>
          </p>
        </div>
        {/*
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
        */}
      </div>
      <div className='background'>
        <div className='overlay'></div>
        <img src={images[image]} />
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-8">
      <div className="container mx-auto text-center">
        <h2 className="text-xl lg:text-3xl mt-6 font-semibold">
          Netoksični, biorazgradivi i ekološki prihvatljivi proizvodi.
        </h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-20">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl mb-12">Prometna industrija</p>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Šinska vozila i tračnice
                </p>
              </div>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Željeznice, tramvaji, zupčane željeznice, planinske željeznice, dizalice
                </p>
              </div>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Naplatci kotača, naplatci gusjenica i zupčanika, tračnice, točkovi, sajle
                </p>
              </div>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl  mb-12">Industrija i tehnologije</p>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Fiksni i vijčani spojevi
                </p>
              </div>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Klizni ležajevi, zupčanici, lanci i sajle, brtve, kočnice
                </p>
              </div>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Otvoreni i zatvoreni zupčanici, svi pokretni dijelovi u visoko korozivnoj atmosferi
                </p>
              </div>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl  mb-12">Majstorske radione</p>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Radionice i domaćinstva
                </p>
              </div>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Kotači motocikala, bicikala, elektrotehničkih i preciznih mehaničkih uređaja
                </p>
              </div>
              <div className='check'><img className="shrink-0" src={check} />
                <p className="text-left">
                  Vrata, brave, šivaći strojevi, kosilice, škare za živicu itd.
                </p>
              </div>
            </Card>
          </div>
        </div>
          <p className="mt-8 md:mt-12">
            <a className="px-4" href="/kategorije">
              <Button size="lg">Više</Button>
            </a>
          </p>
      </div>
    </section>
    <section id="stats" className="py-20 lg:pt-32 lg:pb-32">
      <VisibilitySensor
              active={!viewPortEntered}
              onChange={isVisible => {
                if (isVisible) {
                  setViewPortEntered(true);
                }
              }}
              delayedCall
            >
        <div className="container mx-auto text-center">
          <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
            <div className="w-full justify-center sm:w-1/4">
              <StatsBox primaryText={<div className='stats-image'><img  src={globe} /></div>} secondaryText="Prodaja širom svijeta" />
            </div>
            <div className="w-full justify-center sm:w-1/4">
              <StatsBox primaryText={<CountUp end={42} start={viewPortEntered ? null : 0} />} secondaryText="Godine na tržištu" />
            </div>
            <div className="w-full justify-center sm:w-1/4">
              <StatsBox primaryText={<div className='stats-image small'><img  src={handshake} /></div>}secondaryText="Zadovoljni kupci" />
            </div>
            <div className="w-full justify-center sm:w-1/4">
              <StatsBox primaryText={<CountUp end={20} suffix='+' start={viewPortEntered ? null : 0}/>} secondaryText="Inovativnih proizvoda" />
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
    
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Naši proizvodi</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(product => (
            <div key={product.id} className="flex-1 px-3">
              <CustomerCard product={product} />
            </div>
          ))}
        </div>
      </div>
    </section>
    
    {/*
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our team of enthusiastic marketers will analyse and evaluate how your company stacks
            against the closest competitors
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    */}
    <section className="container mx-auto md:my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Kvaliteta koja vrijedi.</h3>
      <p className="mt-8 text-xl font-light">
        Ukližimo u bolje sutra.
      </p>
      <p className="mt-8">
        <a className="px-4" href="/kontakt">
          <Button size="xl" border>Kontaktirajte nas</Button>
        </a>
      </p>
    </section>
  </Layout>)
}

export default Index;
